import React from 'react'
import './Pages.css';
import { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from '../assets/emc1.jpg';
import img2 from '../assets/emc2.jpg';
import img3 from '../assets/emc3.jpg';
import img4 from '../assets/pic3.jpg';

const descriptions = {
  img1: 'Description for image 1: Welcome to Empower Marketing Concept, where innovation meets expertise in the digital realm.',
  img2: 'Description for image 2: Our skilled developers use the latest web technologies to bring your vision to life.',
  img3: 'Description for image 3: We specialize in maximizing your brand\'s potential through a range of strategic services.',
  img4: 'Description for image 4: We believe that a strong brand identity is key to success. We also offer Photography & Videography services.',
};
const imageKeys = {
  [img1]: 'img1',
  [img2]: 'img2',
  [img3]: 'img3',
  [img4]: 'img4',
};

function About() {

  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, []);

  const [isRotating, setIsRotating] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState();
  // const [currentImage, setCurrentImage] = useState('');

  const handleImageClick = (imgSrc) => {
    const imgKey = imageKeys[imgSrc];
    // console.log(`Image clicked: ${imgKey}`); 
    setIsRotating(false);
    // setCurrentImage(imgSrc);
    setModalContent(descriptions[imgKey]);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsRotating(true);
  };

  return (
    <div className='about'>
      <div className='row'>
        <div className='column' id='abt-col1'>
          <h2 className='abtH2'><span className='flip-in'>About Us</span></h2>
          <p className='parR1'><span className='flip-in'>Welcome to Empower Marketing Concept, where innovation meets expertise in the digital realm. We are committed to propelling your brand to new heights through tailored solutions and unparalleled creativity.</span></p>
          <p className='parR2'><span className='flip-in'>What sets us apart is our commitment to excellence in everything we do. From conceptualization to execution, we prioritize quality, innovation, and results. We take the time to understand your objectives, target audience, and industry landscape to deliver customized strategies that drive tangible outcomes.</span></p>
        </div>
        <div className='column' id='abt-col2' style={{ animationPlayState: isRotating ? 'running' : 'paused' }}>          <img src={img1} className='Cube' alt='Front' />
          <img src={img2} className='Cube' alt='Back' onClick={() => handleImageClick(img2)} />
          <img src={img3} className='Cube' alt='Left' onClick={() => handleImageClick(img3)} />
          <img src={img1} className='Cube' alt='Right' onClick={() => handleImageClick(img1)} />
          <img src={img4} className='Cube' alt='front' onClick={() => handleImageClick(img4)} />
        </div>
      </div>
      {isModalOpen && (
        <div className='modal' onClick={closeModal}>
          <div className='modal-content' onClick={(e) => e.stopPropagation()}>
            <span className='close' onClick={closeModal}>&times;</span>
            <p>{modalContent}</p>
            {/* <img src={currentImage} alt='Current' style={{ width: '100%', height: '400px' }} /> */}
          </div>
        </div>
      )}
    </div>
  )
}

export default About