import React from 'react'
import './Pages.css';
import { useEffect, useState} from 'react';
import logo from '../assets/emc-logo.png';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ZoomModal from '../Subpages/ZoomImage';
import Image1 from '../assets/31.png';
import Image2 from '../assets/30.png';
import Image3 from '../assets/32.png';

function Blog() {

  const [isOpen, setIsOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  const openModal = (src) => {
    setImageSrc(src);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setImageSrc('');
  };

  const handleLinkClick = (event, src) => {
    event.preventDefault(); // Prevent the default link behavior
    openModal(src);
  };

  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, []);

  return (
    <>
      <div className='blog'>
        <div className='comm-links'>
          <Link to='#' onClick={(e) => handleLinkClick(e, Image1)}>
            <img className='logu' src={logo} alt="logo" />
          </Link>
          <Link to='#' onClick={(e) => handleLinkClick(e, Image2)}>
            <img className='logu' src={logo} alt="logo" />
          </Link>
          <Link to='#' onClick={(e) => handleLinkClick(e, Image3)}>
            <img className='logu' src={logo} alt="logo" />
          </Link>
        </div>
      </div>
      <ZoomModal src={imageSrc} alt="Zoomed Image" isOpen={isOpen} onClose={closeModal} />
    </>
  )
}

export default Blog