import React, { useEffect, useState, useRef } from "react";
import { useMediaQuery } from "@uidotdev/usehooks";
import { Link } from "react-router-dom";
// import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { sidebarData } from "./SidebarData";
import "./Navbar.css";
import SocialmediaIcons from "../SocialMedia/SocialmediaIcons";
import "../SocialMedia/Socialmedia.css"
import Topnav from "./Topnav";
import { CgMenuLeft } from "react-icons/cg";

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const sidebarRef = useRef(null);
  const toggleSideBar = () => setSidebar(!sidebar);

  const [bgItem, setBgItem] = useState()
  const handelEnter = (e, item) => {
    setBgItem(item.title)
  }
  const handelLeave = (e, item) => {
    setBgItem()
  }

  const handleDocumentClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSidebar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const isSmallDevice = useMediaQuery("(max-width : 768px)");

  useEffect(() => {
    if (isSmallDevice && sidebar) {
      setSidebar(true);
    }
  }, [isSmallDevice, sidebar]); /*location.pathname, navbtnColor */

  return (
    <>
      <div className="container" id="menuu" ref={sidebarRef}>
        <CgMenuLeft
          size={28}
          className="nav-action-btn ham"
          // style={{ color: navbtnColor }}
          onClick={toggleSideBar}
        />

        {/* sidebar */}
        {/* <nav className={sidebar ? "nav-menu active" : "nav-menu"}> */}
        <nav className={`${bgItem} ${sidebar ? 'nav-menu active' : 'nav-menu'}`} >
          {/* <SidebarHover /> */}
          <div className="nav-header">
            <Topnav />
          </div>
          {/* close btn */}
          <div className="nav-upper">
            <div className="menu-btn">
              <AiIcons.AiOutlineClose
                size={35}
                className="nav-action-btn"
                onClick={toggleSideBar}
              />
            </div>
            {/* menu list */}
            <div className="main-menu">
              <ul className="nav-menu-items" onClick={toggleSideBar}>
                {sidebarData.map((item, index) => {
                  return (
                    <><li key={index} className="nav-menu-item">
                      <Link onMouseLeave={(e) => handelLeave(e, item)} onMouseEnter={(e) => handelEnter(e, item)} to={item.path}>{item.title}</Link>
                    </li>
                    </>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="nav-footer">
            <SocialmediaIcons />
            <p id="navft" style={{marginTop: "45px",color: "#999999"}}>Copyright © 2024&nbsp;&nbsp;<Link id="navft" to="#">Privcy</Link>&nbsp;&nbsp;<Link id="navft" to="#">terms&Conditions</Link>&nbsp;&nbsp;<Link id="navft" to="https://trueview.ae">Legal</Link></p>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Navbar;
