import React from 'react';
import '../pages/Pages.css'; // Import the CSS file

const ZoomModal = ({ src, alt, isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="zoom-modal-overlay" onClick={onClose}>
      <div className="zoom-modal-content">
        <img src={src} alt={alt} className="zoom-modal-image" />
      </div>
    </div>
  );
};

export default ZoomModal;
