
export const sidebarData = [
   // {
   //    title: 'Sectors',
   //    path: '/sectors',
   //    cName: 'nav-text'
   // },
   // {
   //    title: 'CaseStudies',
   //    path: '/case_studies',
   //    cName: 'nav-text'
   // },

   {
      title: 'Community',
      path: '/blog',
      cName: 'nav-text'
   },
   {
      title: 'Services',
      path: '/service',
      cName: 'nav-text'
   },
   {
      title: 'About',
      path: '/about',
      cName: 'nav-text'
   },
   {
      title: 'Careers',
      path: '/careers',
      cName: 'nav-text'
   },
   {
      title: 'Inquire',
      path: '/contact',
      cName: 'nav-text'
   }
]
