import React from 'react'
import './Pages.css';
// import Slider from '../Sliders/Slider';
import Carousel from './Carousel';

function Casestudies() {
  return (
    <div className='case_studies'>
      <Carousel />
    </div>
  )
}

export default Casestudies
