import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaXTwitter, FaThreads  } from "react-icons/fa6";
import { faFacebook, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import '../SocialMedia/Socialmedia.css';

function SocialmediaIcons() {
  return (
    <div className='social-icons'>
        <a href="https://www.instagram.com/uae.emc?igsh=MXNxczhyZ3hxd205Zg==" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a  href="https://www.facebook.com/profile.php?id=61562125976858" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebook} />
      </a>
      <a href="https://linkedin.com/" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faLinkedin} />
      </a>
      <a href="https://x.com/emc_ae" target="_blank" rel="noopener noreferrer">
        <FaXTwitter />
      </a>
      <a href="https://www.threads.net/@uae.emc" target="_blank" rel="noopener noreferrer">
      <FaThreads />
      </a>
      <a href="https://www.youtube.com/@EMC-UAE" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faYoutube} />
      </a>
    </div>
  )
}

export default SocialmediaIcons
