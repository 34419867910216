import React, { Suspense, useState, useEffect } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Service from "./pages/Service";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Casestudies from "./pages/Casestudies";
import Sectors from "./pages/Sectors";
import Careers from "./pages/Careers";
import Request from "./pages/Request";
import Header from './Head/Header';
import Slidein from "./pages/Slidein";
import abtSlide from './assets/abt.png';
import serSlide from './assets/ser.png';
import commSlide from './assets/comm.png';
import crrSlide from './assets/crr.png';
import cntSlide from './assets/cnt.png'
import Sofdev from "./Subpages/Sofdev";
import Webdev from "./Subpages/Webdev";
import Loading from "./pages/Loading";

const Xome = React.lazy(() => import('./pages/Home'));

function App() {

  const [showComponent, setShowComponent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(true);
      setIsLoading(false);
    }, 3000); // 3 seconds delay

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <section>
        <Router>
        {!isLoading && <Header />}
          <Navbar />
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={<Loading />}>
                  {showComponent ? <Xome /> : <Loading />}
                </Suspense>
              }
            />
            {/* <Route path="/" exact Component={Home} /> */}
            <Route path="/about" element={<Slidein imgSrc={abtSlide}><About /></Slidein>} />
            <Route path="/services" element={<Slidein imgSrc={serSlide}><Services /></Slidein>} />
            <Route path="/service" element={<Slidein imgSrc={serSlide}><Service /></Slidein>} />
            <Route path="/blog" element={<Slidein imgSrc={commSlide}><Blog /></Slidein>} />
            <Route path="/contact" element={<Slidein imgSrc={cntSlide}><Contact /></Slidein>} />
            <Route path="/careers" element={<Slidein imgSrc={crrSlide}><Careers /></Slidein>} />
            <Route path="/request" element={<Request />} />
            <Route path="/case_studies" element={<Casestudies />} />
            <Route path="/sectors" element={<Sectors />} />
            <Route path="/sofdev" element={<Sofdev />} />
            <Route path="/webdev" element={<Webdev />} />
          </Routes>
        </Router>
      </section>
    </>
  );
}

export default App;