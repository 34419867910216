import React from 'react'
import './Pages.css';
// import { useEffect } from 'react';
// import AOS from 'aos';
import 'aos/dist/aos.css';

function Careers() {

  // useEffect(() => {
  //   AOS.init({ duration: 2000 })
  // }, []);

  return (
    <div className='careers'>
    </div>
  )
}

export default Careers
