import React from 'react'
import './Pages.css';
import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import img1 from '../assets/wd.png'
import img2 from '../assets/sd.png'
import img3 from '../assets/dm.png'
import img4 from '../assets/gd.png'
import img5 from '../assets/ed.png'


function Services() {

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [modalShow4, setModalShow4] = useState(false);
  const [modalShow5, setModalShow5] = useState(false);
  const [modalShow6, setModalShow6] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

 

  const slides = [img1, img2, img3, img4, img5];
  const captions = [
    'Web Design/ Development',
    'Software / App Development',
    'Digital Marketing',
    'Graphic Design',
    'E-Commerce Development'
  ];
  const description = [
    'We specialize in creating stunning and functional websites that leave a lasting impression. Our skilled developers use the latest web technologies to bring your vision to life. We focus on responsive design, ensuring your website looks great on all devices. We also optimize for performance, fast loading times, and integrate effective call-to-action elements. Plus, we make sure your site is search engine-friendly and easy to navigate.',
    'Our passionate team of skilled developers creates innovative and user-friendly applications tailored to your unique needs. With years of experience and expertise in the latest technologies, we deliver cutting-edge software solutions that drive business growth and enhance user experiences. We take a customized approach, working closely with you from concept to deployment to turn your vision into reality',
    'We specialize in maximizing your brands potential through a range of strategic services From, SEO, SEM & CRO to Content Marketing, Digital Marketing Strategy Consultation, Digital Marketing Analytic, Email Marketing, Digital Advertising, and Social Media Marketing, we have you covered. Our expert team delivers tailored strategies that drive results, whether you want to increase website traffic, improve conversion rates, or enhance brand awareness.',
    'Infinite Graphix, a cooperative company of EMC, offers a Graphic Design service that can take your brand to new heights. Our talented designers will work closely with you to understand your brand values, target audience, and unique selling points. We believe that a strong brand identity is key to success. We also offer Photography & Videography services because we know the importance of high-quality visuals in capturing attention and telling your brands story. We have got you covered from branding to visuals, to motion graphics. ',
    'QR-OWS, a cooperative company of EMC, offers E-commerce Development services that are perfect for businesses. In todays digital world, having a robust and user-friendly e-commerce website is essential for expanding your online presence and reaching a wider audience. At QR-OWS, our skilled team of developers and designers specializes in creating customized e-commerce websites that are tailored to your business unique needs. With our expertise, you can take your online business to new heights and provide your customers with an exceptional shopping journey.'
  ];
 

  description[3] = (
    <p>
      <a href="https://infinitegraphix.ae" target="_blank" rel="noopener noreferrer">Infinite Graphix,</a> a cooperative company of EMC, offers a Graphic Design service that can take your brand to new heights. Our talented designers will work closely with you to understand your brand values, target audience, and unique selling points. We believe that a strong brand identity is key to success. We also offer Photography & Videography services because we know the importance of high-quality visuals in capturing attention and telling your brands story. We have got you covered from branding to visuals, to motion graphics.
    </p>
  );

  description[4] = (
    <p>
      <a href="https://qr-ows.com" target="_blank" rel="noopener noreferrer">QR-OWS,</a> a cooperative company of EMC, offers E-commerce Development services that are perfect for businesses. In today's digital world, having a robust and user-friendly e-commerce website is essential for expanding your online presence and reaching a wider audience. At QR-OWS, our skilled team of developers and designers specializes in creating customized e-commerce websites that are tailored to your business unique needs. With our expertise, you can take your online business to new heights and provide your customers with an exceptional shopping journey.
    </p>
  );

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  useEffect(() => {
    if (!isPaused) {
      const sliderInterval = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
      }, 3500);

      return () =>
        clearInterval(sliderInterval);
    }
  }, [slides.length, isPaused]);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
    if (isPaused) {
      setIsPaused(false);
    }
  }

  const togglePause = () => {
    setIsPaused(!isPaused);
  }

  const showSEOModal = (e) => {
    e.stopPropagation(); // Prevent triggering slider's onClick
    setModalShow(true);
    setIsPaused(true);
  }

  const showContentMarketingModal = (e) => {
    e.stopPropagation(); // Prevent triggering slider's onClick
    setModalShow1(true);
    setIsPaused(true);
  }

  const showDigitalMarketingModal = (e) => {
    e.stopPropagation(); // Prevent triggering slider's onClick
    setModalShow2(true);
    setIsPaused(true);
  }

  const showDigitalMarketingAnalysticModal = (e) => {
    e.stopPropagation(); // Prevent triggering slider's onClick
    setModalShow3(true);
    setIsPaused(true);
  }

  const showEmailMarketingModal = (e) => {
    e.stopPropagation(); // Prevent triggering slider's onClick
    setModalShow4(true);
    setIsPaused(true);
  }

  const showDigitalAdsModal = (e) => {
    e.stopPropagation(); // Prevent triggering slider's onClick
    setModalShow5(true);
    setIsPaused(true);
  }

  const showSMMarketingModal = (e) => {
    e.stopPropagation(); // Prevent triggering slider's onClick
    setModalShow6(true);
    setIsPaused(true);
  }

  const renderDescription = (description, currentSlide) => {
    
    switch (currentSlide) {
      case 2: // Assume "Digital Marketing" is the third slide (index 2)
        return (
          <div>
            {description.split(/[,.]/).map((part, index) => {
              switch (true) {
                case part.includes('SEO'):
                  return (
                    <span key={index} onClick={showSEOModal} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} style={{ cursor: 'pointer', color: hoveredIndex === index ? '#1dc0e9' : '#d8da74', fontWeight: 'bold' }}>SEO, SEM & CRO</span>
                  );
                case part.includes('Content Marketing'):
                  return (
                    <span key={index} onClick={showContentMarketingModal} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} style={{ cursor: 'pointer', color: hoveredIndex === index ? '#1dc0e9' : '#d8da74', fontWeight: 'bold' }}>Content Marketing</span>
                  );
                case part.includes('Digital Marketing Strategy Consultation'):
                  return (
                    <span key={index} onClick={showDigitalMarketingModal} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} style={{ cursor: 'pointer', color: hoveredIndex === index ? '#1dc0e9' : '#d8da74', fontWeight: 'bold' }}>Digital Marketing Strategy Consultation</span>
                  );
                case part.includes('Digital Marketing Analytic'):
                  return (
                    <span key={index} onClick={showDigitalMarketingAnalysticModal} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} style={{ cursor: 'pointer', color: hoveredIndex === index ? '#1dc0e9' : '#d8da74', fontWeight: 'bold' }}>Digital Marketing Analytic</span>
                  );
                case part.includes('Email Marketing'):
                  return (
                    <span key={index} onClick={showEmailMarketingModal} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} style={{ cursor: 'pointer', color: hoveredIndex === index ? '#1dc0e9' : '#d8da74', fontWeight: 'bold' }}>E-Mail Marketing</span>
                  );
                case part.includes('Digital Advertising'):
                  return (
                    <span key={index} onClick={showDigitalAdsModal} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} style={{ cursor: 'pointer', color: hoveredIndex === index ? '#1dc0e9' : '#d8da74', fontWeight: 'bold' }}>Digital Advertising</span>
                  );
                case part.includes('Social Media Marketing'):
                  return (
                    <span key={index} onClick={showSMMarketingModal} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} style={{ cursor: 'pointer', color: hoveredIndex === index ? '#1dc0e9' : '#d8da74', fontWeight: 'bold' }}>Social Media Marketing</span>
                  );
                default:
                  return <span key={index}>{part}</span>;
              }
            }).reduce((prev, curr) => [prev, '.', curr])}
          </div>
        );
      default:
        return <div>{description}</div>;
    }
  };

  return (
    <>
      {/* <TitleSlideshow /> */}
      <div className="slider" onClick={togglePause}>
        <div className='slider-content'>
          <img src={slides[currentSlide]} alt='' className='slider-img'/>
          <div className="caption-overlay">
            {/* <div className='flip-in'> */}
              <div className="caption"><span className="font-link1">
                {captions[currentSlide]}</span>
              </div>
              <div className="description"> <span className="font-link2">
                {renderDescription(description[currentSlide], currentSlide)}</span>
              </div>
            {/* </div> */}
          </div>
          <div className="navigation-lines">
            {slides.map((_, index) => (
              <span
                key={index}
                className={`line ${index === currentSlide ? 'active' : ''}`}
                onClick={() => handleDotClick(index)}
              ></span>
            ))}
          </div>
        </div>
      </div>

      {/* Modal Component */}

      <Modal show={modalShow} onHide={() => setModalShow(false)} dialogClassName="modal-90w">
        <div className="modal-content-custom">
          <Modal.Header closeButton style={{ border: 'none' }}>
            <Modal.Title className="modal-title-center">	SEO, SEM & CRO (Conversion Rate Optimization)</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Our SEO experts optimize your website to improve its visibility in search engine results.
            We conduct keyword research, optimize meta tags, create compelling content, and build high-quality backlinks.
            With targeted advertising campaigns, we help you reach your audience at the right time and place.
            And our CRO experts analyze user behavior, conduct A/B testing, and optimize your website's design to boost conversions
          </Modal.Body></div>
      </Modal>

      <Modal show={modalShow1} onHide={() => setModalShow1(false)} dialogClassName="modal-90w">
        <div className="modal-content-custom">
          <Modal.Header closeButton style={{ border: 'none' }}>
            <Modal.Title className="modal-title-center">Content Marketing</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            Our SEO experts optimize your website to improve its visibility in search engine results.
            We offer a comprehensive suite of Content Marketing services, including Content Management (CM) and Video Marketing.
            With CM, we take care of content creation and distribution, ensuring consistent brand messaging.
            Video Marketing helps to capture attention, convey messages, and drive engagement
          </Modal.Body></div>
      </Modal>

      <Modal show={modalShow2} onHide={() => setModalShow2(false)} dialogClassName="modal-90w">
        <div className="modal-content-custom">
          <Modal.Header closeButton style={{ border: 'none' }}>
            <Modal.Title className="modal-title-center">Digital Marketing Strategy Consultation</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            Our Digital Marketing Strategy Consultation service helps businesses to navigate the digital world
            and develop an effective marketing plan. We will work closely to create a strong brand strategy that
            resonates with your audience. We will also assist in creating viral campaigns and referral programs
            to generate buzz and attract new customers. Additionally, we will implement inbound and
            outbound marketing tactics, including email marketing and social media optimization.
            With Emotional Marketing at the core of our strategy, we will evoke emotions that make your brand memorable and build strong connections with your customers.
          </Modal.Body></div>
      </Modal>

      <Modal show={modalShow3} onHide={() => setModalShow3(false)} dialogClassName="modal-90w">
        <div className="modal-content-custom">
          <Modal.Header closeButton style={{ border: 'none' }}>
            <Modal.Title className="modal-title-center">Digital Marketing Analytic</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            Our Digital Marketing Analytics Service combines top-notch technology and expert analysis to provide
            comprehensive data and actionable insights. With our service, there will be access to a range of
            analytics tools to track KPI across different digital channels, and help in understanding metrics
            like website traffic, conversion rates, customer engagement, and ROI.
          </Modal.Body></div>
      </Modal>

      <Modal show={modalShow4} onHide={() => setModalShow4(false)} dialogClassName="modal-90w">
        <div className="modal-content-custom">
          <Modal.Header closeButton style={{ border: 'none' }}>
            <Modal.Title className="modal-title-center">E-Mail Marketing</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            Our team of experts will work closely to create a comprehensive email marketing strategy that aligns
            with the business goals. We will help to identify the target audience, segment email lists, and design
            eye-catching templates. Our advanced tools will track key metrics like open rates and conversions,
            giving valuable insights to refine the strategy. Additionally, we will ensure your campaigns comply
            with industry regulations like CAN-SPAM and GDPR, prioritizing privacy and trust.
          </Modal.Body></div>
      </Modal>

      <Modal show={modalShow5} onHide={() => setModalShow5(false)} dialogClassName="modal-90w">
        <div className="modal-content-custom">
          <Modal.Header closeButton style={{ border: 'none' }}>
            <Modal.Title className="modal-title-center">Digital Advertising</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            In today's digital world, having a strong online presence is a must. That's where our Digital Advertising
            service comes in! We can customize it to meet your goals, whether it's increasing brand awareness,
            driving traffic, generating leads, or boosting sales. One subcategory is Google Ads, which allows us
            to create targeted ads on Google search and other websites. Another subcategory is Social Media Ads,
            where we can connect with the target audience on platforms like Facebook, Instagram, Twitter, and LinkedIn.
          </Modal.Body></div>
      </Modal>

      <Modal show={modalShow6} onHide={() => setModalShow6(false)} dialogClassName="modal-90w">
        <div className="modal-content-custom">
          <Modal.Header closeButton style={{ border: 'none' }}>
            <Modal.Title className="modal-title-center">Social Media Marketing</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            Social Media Marketing is all about building a strong brand presence, engaging with your audience,
            and driving results. Our service goes beyond just managing accounts and running ads. We will provide
            a comprehensive strategy, content creation, community management, campaign optimization,
            and detailed reporting. One key subcategory is Social Media Management (SMM), where we handle everything
            from creating engaging posts to engaging with your audience. We will develop a customized strategy
            that aligns with your business goals. In Social Media Marketing (SSM),
            we use paid ads to reach a wider audience and drive specific actions
          </Modal.Body></div>
      </Modal>

    </>
  )
}

export default Services