import React from 'react'
import './Pages.css';

function Contact() {
  return (
    <div className='contact'>
    </div>
  )
}

export default Contact