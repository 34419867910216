import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Pages.css'; // Import your CSS file with animations

function Slidein({ imgSrc, children }) {
  const [animate, setAnimate] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Set animate to true when the component mounts or when location changes
    setAnimate(true);
    setShowContent(false);
    // Reset animate after 2 seconds to stop the animation
    const timeout = setTimeout(() => {
      setAnimate(false);
      setShowContent(true);
    }, 1200);

    return () => clearTimeout(timeout); // Cleanup function to clear timeout
  }, [location]);

  return (
    <div className={`animation-wrapper ${animate ? 'slide-in' : ''}`}>
      <img src={imgSrc} alt="Slide" className={`slide-image ${animate ? 'slide-in' : ''}`} />
      {showContent && children}
    </div>
  );
}

export default Slidein;
