// src/components/Loading.js
import React from 'react';
import logo from '../assets/emc-logo.png'
import './Pages.css';

const Loading = () => {

  return (
    <>
    <div className="loading-container">
      <div className="spinner">
      </div>
      <div className="loading-text">
      <img className='Lodimg' src={logo} alt='alt'/>
    <h4 style={{color: '#fdb008'}}>Laoding...</h4></div>
    </div>
    </>
  )
};

export default Loading;