import React from 'react'
import { Link } from "react-router-dom";
import logo from '../assets/emc-logo.png'
import '../Head/header.css'
// import { useState, useEffect } from 'react';

function Header() {

    return (
        <div>
            <div className='header-logo'>
                <Link className='inline-logo' to='/'><img className='Logoimg' src={logo} alt='alt'/></Link>
            </div>
            <div className='header-menu'>
                <Link className='top-inline' to='/request'>Get In Touch</Link>
                {/* <a href="https://www.uae-emc.com/request" className='top-inline' target="_blank">Send Request</a> */}
            </div>
        </div>
    )
}

export default Header
