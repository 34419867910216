import React from 'react'

function Sofdev() {
  return (
    <div className='sofdev'>
      
    </div>
  )
}

export default Sofdev
