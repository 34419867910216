import React from 'react'
import { Link } from 'react-router-dom';
import owsImage from '../assets/qrr.png';
import igImage from '../assets/igg.png';
import "./Navbar.css";

function Topnav() {
  return (
    <div className="nav-header">
      <img className='owslogo' src={owsImage} alt="OWS" />
        <Link className='nav-link1' to="https://qr-ows.com">QR-OWS</Link>
      <img className='iglogo' src={igImage} alt="IG" />
        <Link className="nav-link2" to="https://infinitegraphix.ae">InfiniteGraphix</Link>
        {/* <Link className="nav-link3" to="https://trueview.ae"></Link> */}
    </div>
  )
}

export default Topnav