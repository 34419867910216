import React from 'react';
import './Pages.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import img1 from '../assets/15.png';
import img2 from '../assets/16.png';
import img3 from '../assets/17.png';
import img4 from '../assets/18.png';
import img5 from '../assets/19.png';

function Service() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const slides = [img1, img2, img3, img4, img5];

  useEffect(() => {
    if (!isPaused) {
      const sliderInterval = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
      }, 3500);

      return () => clearInterval(sliderInterval);
    }
  }, [slides.length, isPaused]);

  // const handleDotClick = (index) => {
  //   setCurrentSlide(index);
  //   if (isPaused) {
  //     setIsPaused(false);
  //   }
  // };

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
    setIsPaused(true); // Stop the loop
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    setIsPaused(true); // Stop the loop
  };

  return (
    <div className='service'>
      <img src={slides[currentSlide]} alt='' onClick={togglePause} className='slider-img' />
      {currentSlide === 0 && (
        <div className='slide-text'>
          <Link to='/webdev' style={{ color: 'transparent', textDecoration: 'none' }}>Sof dev</Link>
        </div>
      )}
      {currentSlide === 1 && (
        <div className='slide-text'>
          <Link to='/sofdev' style={{ color: 'transparent', textDecoration: 'none' }}>Web dev</Link>
        </div>
      )}
      {currentSlide === 2 && (
        <div className='slide-text'>
          <Link to='#' style={{ color: 'transparent', textDecoration: 'none' }}>#</Link>
        </div>
      )}
      {currentSlide === 3 && (
        <div className='slide-text'>
          <a style={{ color: 'transparent', textDecoration: 'none' }} href='https://infinitegraphix.ae' target='_blank' rel='noopener noreferrer'>IIIInfiniteeeeeee</a>
        </div>
      )}
      {currentSlide === 4 && (
        <div className='slide-text'>
          <a style={{ color: 'transparent', textDecoration: 'none' }} href='https://qr-ows.com' target='_blank' rel='noopener noreferrer'>qr-ows.com</a>
        </div>
      )}
      <div className="arrow-buttons">
        <button className="prev" onClick={handlePrevSlide}>❮</button>
        <button className="next" onClick={handleNextSlide}>❯</button>
        {/* {slides.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentSlide ? 'active' : ''}`}
                onClick={() => handleDotClick(index)}
              ></span>
            ))} */}
      </div>
    </div>
  );
}

export default Service;
