import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
import './Pages.css';
import videoEMC from '../assets/emce.mp4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const Request = () => {
  const form = useRef();
  const [messageSent, setMessageSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_x4ovh6f",
        "template_0ai2zlr",
        form.current,
        "RsjIcl4wWnBWdRUtt"
      )
      .then(
        (result) => {
          setMessageSent(true);
          console.log(result.text);
        },
        (error) => {
          setMessageSent(false);
          console.log(error.text);
        }
      );
  };

  return (
    <div className='request'>
      <video autoPlay muted loop className="background-video">
        <source src={videoEMC} type="video/mp4" />
      </video> 
      <div className="row" id='requst'>
        <div className="column" style={{ width: '60%' }}>
          <StyledContactForm>
          <h2 >Get in Touch</h2>
            <form ref={form} onSubmit={sendEmail} >
              <label>Name</label>
              <input type="text" name="from_name" required />
              <label>Email</label>
              <input type="email" name="user_email" required />
              <label>Message</label>
              <textarea name="message" required />
              <input type="submit" value="Send" />
            </form>
          </StyledContactForm>
        </div>
        <div className="column"  id="reach" style={{ width: '40%' }}>
          <h4>Reach Us</h4>
          <a href="mailto:info@uae-emc.com" className='flip-in' id="reach" >Info@uae-emc.com</a><br />
            <a href="tel:+971501498588 " className='flip-in' id="reach">+971501498588 </a>
          <p className='flip-in'>Abu Dhabi, UAE.</p>
          <h4 className='header flip-in'><a href="/" target="_blank" rel="noopener noreferrer" id="reach">
            <FontAwesomeIcon icon={faMapMarkerAlt} />Get Direction</a></h4>
        </div>
      </div>

      {messageSent && <p id="succmsg" >Message sent successfully!</p>}
    </div>
  );
};

export default Request;


// Styles
const StyledContactForm = styled.div`
  width: 400px;
  margin-left: 36%;

  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;

    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    label {
      margin-top: 1rem;
    }

    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: rgb(249, 105, 14);
      color: white;
      border: none;
    }
  }
`;