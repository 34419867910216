// Carousel.js

import React, { useState, useEffect } from 'react';
import '../pages/Pages.css';
import img1 from '../assets/pic2.jpg'
// import img2 from '../assets/pic2.jpg'
// import img3 from '../assets/pic1.jpg'

const Carousel = () => {

  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [ img1]; /* img2, img3, */
  const captions = ['Caption for Image 2', 'Caption for Image 3', 'Caption for Image 1'];

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 3000);

    return () => {
      clearInterval(sliderInterval);
    };
  }, [slides.length]);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  }
  return (
    <div>
      <div className="slider">
        <div className='slider-content'>
        <img src={slides[currentSlide]} alt='' />
        <div className="caption-overlay">
            <div className="caption">{captions[currentSlide]}</div>
          </div>
        </div>
      <div className="navigation-lines">
          {slides.map((_, index) => (
            <span
              key={index}
              className={`line ${index === currentSlide ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
        </div>
    </div>
  );
}

export default Carousel
