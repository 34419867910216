import {React, useState} from 'react'
import { Link } from "react-router-dom";
import '../pages/Pages.css';
import videoFile from '../assets/emce.mp4';

function Sectors() {

  const [isHovered, setHovered] = useState(false);

  const hoveronLink = (e) => {
    // console.log('HoverLink')
    setHovered(videoFile)
  }

  const hoveroffLink = (e) => {
    setHovered('')
  }

  return (
    <div className='sectors'>
      <h2><Link onMouseEnter={(e) => hoveronLink(e)} onMouseLeave={(e) => hoveroffLink(e)} className='sec-menu' to='#'>Energy</Link></h2>
      <h2><Link onMouseEnter={(e) => hoveronLink(e)} onMouseLeave={(e) => hoveroffLink(e)} className='sec-menu' to='#'>Fin Tech</Link></h2>
      <h2><Link onMouseEnter={(e) => hoveronLink(e)} onMouseLeave={(e) => hoveroffLink(e)} className='sec-menu' to='#'>Blockchain</Link></h2>
      <h2><Link onMouseEnter={(e) => hoveronLink(e)} onMouseLeave={(e) => hoveroffLink(e)} className='sec-menu' to='#'>Insurance</Link></h2>
      <h2><Link onMouseEnter={(e) => hoveronLink(e)} onMouseLeave={(e) => hoveroffLink(e)} className='sec-menu' to='#'>Banking</Link></h2>
      <h2><Link onMouseEnter={(e) => hoveronLink(e)} onMouseLeave={(e) => hoveroffLink(e)} className='sec-menu' to='#'>Utilities</Link></h2>
      {/* <Videopreview /> */}
      {isHovered && (
        <div className="video-preview">
          <video autoPlay muted loop> <source src={videoFile} type="video/mp4" /></video>
        </div>
      )}
    </div>
    
  )
}

export default Sectors